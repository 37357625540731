/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.card {
  position: relative;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
.card > .ant-card-head {
  min-height: 10px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 0 none;
  margin-bottom: 0;
}
.card > .ant-card-head .ant-card-head-title {
  line-height: 1.4993rem;
  padding-bottom: 4px;
}
.card > .ant-card-head .ant-card-extra {
  padding-bottom: 4px;
}
.card > .ant-card-head .ant-card-extra a {
  color: #F04F47;
}
.card > .ant-card-body {
  padding-top: 15px;
}
.card.heightFull {
  height: 100%;
}
.card.heightFull .ant-card-body {
  flex: 1;
}
.card.heightFull.ant-card::-webkit-scrollbar {
  display: none;
}
.card.heightAuto {
  height: auto;
}
.card.heightAuto .ant-card-body {
  flex: 1;
}
.card.heightAuto.ant-card::-webkit-scrollbar {
  display: none;
}
.card .ant-card-actions {
  background-color: transparent;
}
.card-outer-title {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  color: #111827;
}
.no-card-space .ant-card-body {
  padding: 0;
}
.no-card-space-ltr-rtl .ant-card-body {
  padding-left: 0;
  padding-right: 0;
}
