/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-layout {
  min-height: 100vh;
  position: relative;
  background: #F4F7FE;
  overflow-x: hidden;
}
.app-layout.appMainFixedFooter {
  padding-bottom: 46px;
}
.app-layout.appMainFixedFooter .app-main-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
}
.app-layout .ant-layout {
  background: none;
}
.ant-layout-sider-zero-width-trigger {
  display: none;
}
.app-main-sidebar.ant-layout-sider {
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 0 !important;
  width: 17.5rem !important;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  background-color: inherit;
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .app-main-sidebar.ant-layout-sider {
    width: 15rem !important;
  }
}
.app-main-sidebar.ant-layout-sider-dark {
  background-color: inherit;
}
.app-main-sidebar .ant-layout-sider-trigger {
  display: none;
}
.app-main-sidebar.ant-layout-sider-has-trigger {
  padding-bottom: 0;
}
.app-main-sidebar.ant-layout-sider-collapsed {
  width: 0 !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media screen and (min-width: 992px) {
  .app-main-sidebar.ant-layout-sider-collapsed {
    width: 4rem !important;
  }
}
.app-main-sidebar.ant-layout-sider-collapsed + .app-layout-main {
  width: 100% !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media screen and (min-width: 992px) {
  .app-main-sidebar.ant-layout-sider-collapsed + .app-layout-main {
    width: calc(100% - 4rem) !important;
  }
}
.app-main-sidebar.ant-layout-sider-collapsed .cr-user-info {
  padding-left: 12px;
  padding-right: 12px;
}
.app-main-sidebar.ant-layout-sider-collapsed .cr-user-info-content {
  opacity: 0;
  visibility: hidden;
  width: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
}
[dir=rtl] .app-main-sidebar.ant-layout-sider-collapsed .cr-user-info-content {
  margin-right: 0;
}
.app-main-sidebar.ant-layout-sider-collapsed .ant-menu-item-group-title {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
}
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app-header {
  padding-left: 20px;
  padding-right: 20px;
  color: #111827;
  background-color: #fff;
  height: 56px;
  line-height: 1;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .app-header {
    padding-left: 30px;
    padding-right: 30px;
    height: 71px;
  }
}
.app-header .trigger {
  font-size: 20px;
  margin-right: 10px;
  padding: 5.5px;
  color: #111827;
}
[dir=rtl] .app-header .trigger {
  margin-right: 0;
  margin-left: 10px;
}
@media screen and (min-width: 992px) {
  .app-header .trigger {
    margin-right: 20px;
  }
  [dir=rtl] .app-header .trigger {
    margin-right: 0;
    margin-left: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .app-header .trigger {
    display: none;
  }
}
.app-header .trigger svg {
  display: block;
}
.app-header-sectionDesktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .app-header-sectionDesktop {
    display: flex;
    align-items: center;
  }
}
.app-header-section-mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .app-header-section-mobile {
    display: none;
  }
}
.app-header-section-mobile .ant-dropdown-link {
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #F4F7FE;
  color: #111827;
  padding: 9px;
  border-radius: 50%;
  border: solid 1px transparent;
}
.app-header-section-mobile .ant-dropdown-link:hover,
.app-header-section-mobile .ant-dropdown-link:focus {
  color: #111827;
  background-color: #f6f9fe;
  border-color: #F4F7FE;
}
.app-header-section-mobile .ant-dropdown-link svg {
  display: block;
}
.app-layout-main {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100% !important;
  position: relative;
  flex-shrink: 0;
}
@media screen and (min-width: 1200px) {
  .app-layout-main {
    flex-shrink: inherit;
    width: calc(100% - 17.5rem) !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .app-layout-main {
    width: calc(100% - 15rem) !important;
  }
}
.main-scrollbar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-height: calc(100vh - 56px);
}
.appMainFixedFooter .main-scrollbar {
  max-height: calc(100vh - 104px);
}
@media screen and (min-width: 768px) {
  .main-scrollbar {
    max-height: calc(100vh - 71px);
  }
  .appMainFixedFooter .main-scrollbar {
    max-height: calc(100vh - 118px);
  }
}
.app-sidebar-scrollbar {
  height: calc(100vh - 56px);
}
.appMainFixedFooter .app-sidebar-scrollbar {
  height: calc(100vh - 102px);
}
@media screen and (min-width: 768px) {
  .app-sidebar-scrollbar {
    height: calc(100vh - 71px);
  }
  .appMainFixedFooter .app-sidebar-scrollbar {
    height: calc(100vh - 116px);
  }
}
.sidebar-img-background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.sidebar-img-background:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  z-index: 1;
  opacity: 0.5;
}
.sidebar-img-background > div {
  position: relative;
  z-index: 3;
}
.sidebar-img-background .ant-menu.ant-menu-dark,
.sidebar-img-background .ant-menu-dark .ant-menu-sub,
.sidebar-img-background .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent;
  color: inherit;
}
.sidebar-img-background .app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
.sidebar-img-background .app-main-sidebar-menu .ant-menu-item a {
  color: inherit;
}
.sidebar-img-background .ant-menu {
  background-color: transparent;
}
