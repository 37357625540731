/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.table-responsive .ant-table {
  min-height: 0.01%;
  overflow-x: auto;
}
.table-responsive thead > tr > th,
.table-responsive tbody > tr > th,
.table-responsive tfoot > tr > th,
.table-responsive thead > tr > td,
.table-responsive tbody > tr > td,
.table-responsive tfoot > tr > td {
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .table-responsive .ant-table {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: solid 1px rgba(0, 0, 0, 0.12);
  }
}
.hoverColor tbody > tr {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.hoverColor tbody > tr:hover {
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(10, 143, 220, 0.1);
}
.hoverColor .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: transparent;
}
